'use client'

import { ApolloError } from '@apollo/client'
import { GraphQLError } from 'graphql'
import { useCallback, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'

import { gql } from '../../__generated__/graphql/catalog'
import { QueryError, QueryStatus, convertGraphqlErrorToQueryError } from '../../lib/hooks/types'
import { useGoogleTagManager } from '../../lib/hooks/useGoogleTagManager/useGoogleTagManager'
import {
  AccountAuthorization,
  convertCookiesToAccountAuthorization,
} from '../_config/Authentication.config'
import { cookieKeys } from '../_config/Cookies.config'

import { useCookies } from './CookiesProvider.client'
import { useNextMutation } from './GraphqlClientsProvider.client'

type Destination = string | URL | (() => void)

const navigateToDestination = (destination: Destination) => {
  if (typeof destination === 'function') {
    destination()
  } else if (typeof destination === 'string') {
    window.location.href = destination
  } else {
    window.location.href = destination.toString()
  }
}

export type UseAuthenticationOutput = {
  account: AccountAuthorization | undefined
  isLoggedIn: boolean
  logOut: (options?: { destination?: Destination }) => void
  logoutMutationStatus: QueryStatus
  logIn: (credentials: { email: string; password: string; destination?: Destination }) => void
  loginMutationErrors: QueryError[]
  loginMutationStatus: QueryStatus
  activateAccount: (credentials: {
    activationUrl: string
    password: string
    destination?: Destination
  }) => void
  activateAccountMutationErrors: QueryError[]
  activateAccountMutationStatus: QueryStatus
  createAccount: (credentials: {
    email: string
    emailOptIn: boolean
    firstName: string
    lastName: string
    password: string
    destination?: Destination
  }) => void
  createAccountMutationErrors: QueryError[]
  createAccountMutationStatus: QueryStatus
  navigateToLogin: (options: {
    sso?: {
      organizationId: string
      organizationShopifyCustomerTag: string
    }
    redirectUrl?: string
    navigationMethod: 'assign' | 'replace'
  }) => void
}

const useAuthenticationImpl = (): UseAuthenticationOutput => {
  const { pushAccountCreated } = useGoogleTagManager()
  const [cookies, setCookie, removeCookie] = useCookies([
    cookieKeys.authToken.key,
    cookieKeys.authRefreshToken.key,
    cookieKeys.shopifyCustomerToken.key,
  ])

  const authCookie = cookies[cookieKeys.authToken.key]
  const authRefreshCookie = cookies[cookieKeys.authRefreshToken.key]
  const shopifyCustomerCookie = cookies['figs-customer-access-token']

  const removeAuthCookies = useCallback(() => {
    removeCookie(cookieKeys.authToken.key, {
      path: '/',
    })
    removeCookie(cookieKeys.authRefreshToken.key, {
      path: '/',
    })
    removeCookie(cookieKeys.shopifyCustomerToken.key, {
      path: '/',
    })
  }, [removeCookie])

  const setAuthCookies = useCallback(
    ({
      newAuthCookie,
      newAuthRefreshCookie,
      newShopifyCustomerCookie,
    }: {
      newAuthCookie: string
      newAuthRefreshCookie: string
      newShopifyCustomerCookie: string
    }) => {
      // Set our 3 auth cookies.
      setCookie(cookieKeys.authToken.key, `Bearer ${newAuthCookie}`, cookieKeys.authToken.options)
      setCookie(
        cookieKeys.authRefreshToken.key,
        newAuthRefreshCookie,
        cookieKeys.authRefreshToken.options
      )
      setCookie(
        cookieKeys.shopifyCustomerToken.key,
        newShopifyCustomerCookie,
        cookieKeys.shopifyCustomerToken.options
      )
    },
    [setCookie]
  )

  const authenticationStatus = useMemo(() => {
    return convertCookiesToAccountAuthorization({
      authCookie,
      authRefreshCookie,
      shopifyCustomerCookie,
    })
  }, [authCookie, authRefreshCookie, shopifyCustomerCookie])

  const [logoutMutationStatus, setLogoutMutationStatus] =
    useState<UseAuthenticationOutput['logoutMutationStatus']>('idle')
  const logOut = useCallback<UseAuthenticationOutput['logOut']>(
    options => {
      setLogoutMutationStatus('pending')

      // Clear our 3 auth cookies. This is all we should have to do really.
      removeAuthCookies()

      // It is important to do this hard refresh to make sure all 3rd parties are reset.
      navigateToDestination(options?.destination ?? '/')
    },
    [removeAuthCookies]
  )

  const [activateAccountMutationErrors, setActivateAccountMutationErrors] = useState<
    UseAuthenticationOutput['activateAccountMutationErrors']
  >([])
  const [activateAccountMutationStatus, setActivateAccountMutationStatus] =
    useState<UseAuthenticationOutput['activateAccountMutationStatus']>('idle')
  const [activateAccountMutation] = useNextMutation(
    gql(`
      mutation ActivateAccount($activationUrl: String!, $password: String!) {
        activateCustomer(activationUrl: $activationUrl, password: $password) {
          customerAccessToken
          refreshToken
          token
        }
      }
  `)
  )

  const activateAccount = useCallback<UseAuthenticationOutput['activateAccount']>(
    ({ activationUrl, password, destination }) => {
      if (activateAccountMutationStatus !== 'pending') {
        setActivateAccountMutationErrors([])
        setActivateAccountMutationStatus('pending')

        activateAccountMutation({
          variables: { activationUrl, password },
        })
          .then(activateAccountMutationResult => {
            const errors = activateAccountMutationResult.errors
              ? activateAccountMutationResult.errors.map(convertGraphqlErrorToQueryError)
              : []

            if (errors.length > 0 || !activateAccountMutationResult.data) {
              if (!activateAccountMutationResult.data) {
                errors.push({
                  status: 500,
                  message: 'Failed to retrieve a full response.',
                })
              }

              setActivateAccountMutationErrors(errors)
              setActivateAccountMutationStatus('rejected')
            } else {
              // Set our 3 auth cookies.
              setAuthCookies({
                newAuthCookie: activateAccountMutationResult.data.activateCustomer.token,
                newAuthRefreshCookie:
                  activateAccountMutationResult.data.activateCustomer.refreshToken,
                newShopifyCustomerCookie:
                  activateAccountMutationResult.data.activateCustomer.customerAccessToken,
              })

              // It is important to do this hard refresh to make sure all 3rd parties are reset.
              navigateToDestination(destination ?? '/account')
            }
          })
          .catch(_error => {
            setActivateAccountMutationErrors([
              {
                status: 500,
                message: 'Uncategorized error',
              },
            ])
            setActivateAccountMutationStatus('rejected')
          })
      }
    },
    [activateAccountMutation, activateAccountMutationStatus, setAuthCookies]
  )

  const [loginMutationErrors, setLoginMutationErrors] = useState<
    UseAuthenticationOutput['loginMutationErrors']
  >([])
  const [loginMutationStatus, setLoginMutationStatus] =
    useState<UseAuthenticationOutput['loginMutationStatus']>('idle')
  const [loginMutation] = useNextMutation(
    gql(`
      mutation LoginUser($email: String!, $password: String!) {
        loginCustomer(email: $email, password: $password) {
          customerAccessToken
          refreshToken
          token
        }
      }
  `)
  )

  const logIn = useCallback<UseAuthenticationOutput['logIn']>(
    ({ email, password, destination }) => {
      if (loginMutationStatus !== 'pending') {
        setLoginMutationErrors([])
        setLoginMutationStatus('pending')

        loginMutation({
          variables: { email, password },
        })
          .then(loginMutationResult => {
            const errors = loginMutationResult.errors
              ? loginMutationResult.errors.map(convertGraphqlErrorToQueryError)
              : []

            if (errors.length > 0 || !loginMutationResult.data) {
              if (!loginMutationResult.data) {
                errors.push({
                  status: 500,
                  message: 'Failed to retrieve a full response.',
                })
              }

              setLoginMutationErrors(errors)
              setLoginMutationStatus('rejected')
            } else {
              // Set our 3 auth cookies.
              setAuthCookies({
                newAuthCookie: loginMutationResult.data.loginCustomer.token,
                newAuthRefreshCookie: loginMutationResult.data.loginCustomer.refreshToken,
                newShopifyCustomerCookie:
                  loginMutationResult.data.loginCustomer.customerAccessToken,
              })

              // It is important to do this hard refresh to make sure all 3rd parties are reset.
              navigateToDestination(destination ?? '/account')
            }
          })
          .catch(_error => {
            setLoginMutationErrors([
              {
                status: 500,
                message: 'Uncategorized error',
              },
            ])
            setLoginMutationStatus('rejected')
          })
      }
    },
    [loginMutation, loginMutationStatus, setAuthCookies]
  )

  const [createAccountMutationErrors, setCreateAccountMutationErrors] = useState<
    UseAuthenticationOutput['createAccountMutationErrors']
  >([])
  const [createAccountMutationStatus, setCreateAccountMutationStatus] =
    useState<UseAuthenticationOutput['createAccountMutationStatus']>('idle')
  const [createAccountMutation] = useNextMutation(
    gql(`
      mutation CreateUser($input: CustomerInput!) {
        createCustomer(input: $input) {
          customerAccessToken
          refreshToken
          token
        }
      }
  `)
  )

  const createAccount = useCallback<UseAuthenticationOutput['createAccount']>(
    ({ email, emailOptIn, firstName, lastName, password, destination }) => {
      if (createAccountMutationStatus !== 'pending') {
        setCreateAccountMutationErrors([])
        setCreateAccountMutationStatus('pending')

        createAccountMutation({
          variables: { input: { email, emailOptIn, firstName, lastName, password } },
        })
          .then(createAccountMutationResult => {
            const errors = createAccountMutationResult.errors
              ? createAccountMutationResult.errors.map(convertGraphqlErrorToQueryError)
              : []

            if (errors.length > 0 || !createAccountMutationResult.data) {
              if (!createAccountMutationResult.data) {
                errors.push({
                  status: 500,
                  message: 'Failed to retrieve a full response.',
                })
              }

              setCreateAccountMutationErrors(errors)
              setCreateAccountMutationStatus('rejected')
            } else {
              // Set our 3 auth cookies.
              setAuthCookies({
                newAuthCookie: createAccountMutationResult.data.createCustomer.token,
                newAuthRefreshCookie: createAccountMutationResult.data.createCustomer.refreshToken,
                newShopifyCustomerCookie:
                  createAccountMutationResult.data.createCustomer.customerAccessToken,
              })

              pushAccountCreated(email)

              // It is important to do this hard refresh to make sure all 3rd parties are reset.
              navigateToDestination(destination ?? '/account/profile/complete')
            }
          })
          .catch(error => {
            if (error instanceof ApolloError || error instanceof GraphQLError) {
              setCreateAccountMutationErrors([convertGraphqlErrorToQueryError(error)])
            } else {
              setCreateAccountMutationErrors([
                {
                  status: 500,
                  message: 'Uncategorized error',
                },
              ])
            }
            setCreateAccountMutationStatus('rejected')
          })
      }
    },
    [createAccountMutation, createAccountMutationStatus, pushAccountCreated, setAuthCookies]
  )

  const navigateToLogin = useCallback<UseAuthenticationOutput['navigateToLogin']>(
    ({ redirectUrl: redirectUrlString, navigationMethod, sso: ssoProp }) => {
      const sso = window.location.search.includes('sso_redirect=true') ? undefined : ssoProp

      const loginSearchParams = new URLSearchParams()
      const loginPath = sso
        ? `/account-services/auth/saml/${sso.organizationId}/login`
        : `/account/login`

      const redirectUrl = new URL(
        redirectUrlString ?? `${window.location.protocol}//${window.location.host}/`
      )
      if (sso) {
        redirectUrl.searchParams.set('sso_redirect', 'true')
      }
      loginSearchParams.set('redirectUrl', redirectUrl.toString())

      if (sso?.organizationShopifyCustomerTag) {
        loginSearchParams.set('groupTag', sso.organizationShopifyCustomerTag)
      }

      window.location[navigationMethod](`${loginPath}?${loginSearchParams.toString()}`)
    },
    []
  )

  return {
    account: authenticationStatus,
    isLoggedIn: !!authenticationStatus,
    logOut,
    logoutMutationStatus,
    logIn,
    loginMutationErrors,
    loginMutationStatus,
    activateAccount,
    activateAccountMutationErrors,
    activateAccountMutationStatus,
    createAccount,
    createAccountMutationErrors,
    createAccountMutationStatus,
    navigateToLogin,
  }
}

const AuthenticationContainer = createContainer(useAuthenticationImpl)
AuthenticationContainer.Provider.displayName = 'AuthenticationProviderImpl'
export const AuthenticationProvider = AuthenticationContainer.Provider
export const useAuthentication = AuthenticationContainer.useContainer
